import './App.css';
import { useEffect, useState } from 'react';

import { TypeAnimation } from 'react-type-animation';

const AnimatedText = () => {
  return (
    <TypeAnimation
      sequence={[
        // Same substring at the start will only be typed out once, initially
        'We build full-stack web3 apps',
        2000, // wait 1s before replacing "Mice" with "Hamsters"
        'We build AI solutions',
        2000,
        'We focus on simple user experience',
        2000,
        'We turn your ideas into reality',
        2000
      ]}
      wrapper="span"
      speed={75}
      className="animatedText"
      repeat={Infinity}
    />
  );
};

function App() {
  const [currentSection, setCurrentSection] = useState(0);
  const sections = ['header1', 'header2', 'header3', 'header4', 'header5', 'header6'];
  const totalSections = sections.length;

  useEffect(() => {
    const handleScroll = () => {
      let current = 0;
      sections.forEach((section, index) => {
        const element = document.getElementById(section);
        const rect = element.getBoundingClientRect();
        if (rect.top >= 0 && rect.top < window.innerHeight / 2) {
          current = index;
        }
      });
      setCurrentSection(current);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const menuItems = document.querySelectorAll('.menuitem');
    menuItems.forEach((item, index) => {
      item.addEventListener('click', () => {
        setCurrentSection(index);
        document.getElementById(sections[index]).scrollIntoView({ behavior: 'smooth' });
      });
    });
  }, []);

  const handleScrollDown = () => {
    const nextSection = (currentSection + 1) % totalSections;
    setCurrentSection(nextSection);
    document.getElementById(sections[nextSection]).scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    function updateVh() {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
  }
  
  // Run on initial load
  updateVh();
  
  // Run on resize
  window.addEventListener('resize', updateVh);
  }, []);

  return (
    <>
      <div className="App">
        <div className="menu">
          <div className="menuitem">Home</div>
          <div className="menuitem">About</div>
          <div className="menuitem">Portfolio</div>
          <div className="menuitem">Techstack</div>
          <div className="menuitem">Team</div>
          <div className="menuitem menuChat">Chat</div>
        </div>
        <div id="header1" className="header">
          <div className="name"><span className="async">Asynchronous</span> Developers</div>
          <AnimatedText />
        </div>
        <div id="header2" className="header">
          <div className="title">What do we offer?</div>
          <div className="weOffer">We are full-stack web3 developers, building decentralized applications since 2019. We also regularly work on AI solutions. If you're looking to build a web3 or an AI solution, we may just be the perfect match to <span className="textHighlight">bring your idea to reality.</span></div>
        </div>
        <div id="header3" className="header">
          <div className="title">Work we've done</div>
          <div className="projects">
          <div className="project">
              <div className="projectTitle"><img src="assets/msigchat.png" width="28px" height="28px"/>msig.chat</div>
              <div className="projectDescription">Encrypted on-chain chat app, for accounts with msig.</div>
              <a href="https://msig.chat" target="_blank" className="visitProject">Visit project</a>
            </div>
            <div className="project">
              <div className="projectTitle"><img src="assets/lollipop.png" width="28px" height="28px"/>lollipop.chat</div>
              <div className="projectDescription">The most advanced AI girls in the world, with unfiltered chat, life-like voice, and hyperrealistic photos.</div>
              <a href="https://lollipop.chat" target="_blank" className="visitProject">Visit project</a>
            </div>
            <div className="project">
              <div className="projectTitle"><img src="assets/jobboard.svg" width="28px" height="28px"/>job-board.io</div>
              <div className="projectDescription">On-chain platform to connect workers with work providers.</div>
              <a href="https://job-board.io" target="_blank" className="visitProject">Visit project</a>
            </div>
            <div className="project">
              <div className="projectTitle"><img src="assets/consortium.png" width="28px" height="28px"/>consortium.vote</div>
              <div className="projectDescription">Token based on-chain voting.</div>
              <a href="https://consortium.vote" target="_blank" className="visitProject">Visit project</a>
            </div>
            <div className="project">
              <div className="projectTitle"><img src="assets/eosetf.svg" width="28px" height="28px"/>eosetf.io</div>
              <div className="projectDescription">Decentralized exchange traded fund.</div>
              <a href="https://eosetf.io" target="_blank" className="visitProject">Visit project</a>
            </div>
            <div className="project">
              <div className="projectTitle"><img src="assets/daodrp.png" width="28px" height="28px"/>daodrp.com</div>
              <div className="projectDescription">On-chain dispute resolution process.</div>
              <a href="https://daodrp.com" target="_blank" className="visitProject">Visit project</a>
            </div>
            <div className="project">
              <div className="projectTitle"><img src="assets/eosden.png" width="28px" height="28px"/>web3.eosde.net</div>
              <div className="projectDescription">Aggregator of web3 solutions.</div>
              <a href="https://web3.eosde.net" target="_blank" className="visitProject">Visit project</a>
            </div>
            <div className="project">
              <div className="projectTitle"><img src="assets/optimism.png" width="28px" height="28px"/>optimismfractal.web.app</div>
              <div className="projectDescription">Innovative governance solution (we worked on the UI).</div>
              <a href="https://optimismfractal.web.app" target="_blank" className="visitProject">Visit project</a>
            </div>
            <div className="project">
              <div className="projectTitle"><img src="assets/daofractal.png" width="28px" height="28px"/>fractal.upscalenow.io</div>
              <div className="projectDescription">Innovative governance solution on steroids (we worked on the smart contracts).</div>
              <a href="https://fractal.upscalenow.io" target="_blank" className="visitProject">Visit project</a>
            </div>
            <div className="project">
              <div className="projectTitle"><img src="assets/followcaster.png" width="28px" height="28px"/>followcaster.com</div>
              <div className="projectDescription">Buy farcaster followers, or earn by discovering interesting accounts to follow.</div>
              <a href="https://followcaster.com" target="_blank" className="visitProject">Visit project</a>
            </div>
            <div className="project">
              <div className="projectTitle"><img src="assets/cryptofund.png" width="28px" height="28px"/>cryptofund.global</div>
              <div className="projectDescription">On-chain investment fund on Base L2.</div>
              <a href="https://cryptofund.global" target="_blank" className="visitProject">Visit project</a>
            </div>
          </div>
          <div className="andMore">And many more.</div>
        </div>
        <div id="header4" className="header">
          <div className="title">What we excel at</div>
          <div className="techStackContainer">
            <div className="techStack">
              <img width="48px" className="techStackImg" src="assets/react.svg" alt="React" />
              <div>React</div>
            </div>
            <div className="techStack">
              <img width="48px" className="techStackImg" src="assets/node-js.svg" alt="Node.js" />
              <div>Node.js</div>
            </div>
            <div className="techStack">
              <img width="48px" className="techStackImg" src="assets/javascript.svg" alt="JavaScript" />
              <div>JavaScript</div>
            </div>
            <div className="techStack">
              <img width="48px" className="techStackImg" src="assets/express-js.svg" alt="Express.js" />
              <div>Express.js</div>
            </div>
            <div className="techStack">
              <img width="48px" className="techStackImg" src="assets/sql.webp" alt="SQL" />
              <div>SQL</div>
            </div>
            <div className="techStack">
              <img width="48px" className="techStackImg" src="assets/firebase.png" alt="Firebase" />
              <div>Firebase</div>
            </div>
            <div className="techStack">
              <img width="48px" className="techStackImg" src="assets/analytics.png" alt="Python" />
              <div>Google Analytics</div>
            </div>
            <div className="techStack">
              <img width="48px" className="techStackImg" src="assets/googleads.png" alt="Googleads" />
              <div>Google Ads</div>
            </div>
            <div className="techStack">
              <img width="48px" className="techStackImg" src="assets/python.png" alt="Python" />
              <div>Python</div>
            </div>
            <div className="techStack">
              <img width="48px" className="techStackImg" src="assets/css.svg" alt="CSS" />
              <div>CSS3</div>
            </div>
            <div className="techStack">
              <img width="48px" className="techStackImg" src="assets/react.svg" alt="React Native" />
              <div>React Native</div>
            </div>
            <div className="techStack">
              <img width="48px" className="techStackImg" src="assets/docker.svg" alt="Docker" />
              <div>Docker</div>
            </div>
            <div className="techStack">
              <img width="48px" className="techStackImg" src="assets/aws.svg" alt="aws" />
              <div>AWS</div>
            </div>
            <div className="techStack">
              <img width="48px" className="techStackImg" src="assets/cpp.png" alt="c++" />
              <div>C++</div>
            </div>
          </div>
          <div className="title">We can build on</div>
          <div className="techStackContainer">
          <div className="techStack">
              <img width="48px" className="techStackImg" src="assets/base.png" alt="Base" />
              <div>Base</div>
            </div>
            <div className="techStack">
              <img width="48px" className="techStackImg" src="assets/optimism.png" alt="Optimism" />
              <div>Optimism</div>
            </div>
            <div className="techStack">
              <img width="48px" className="techStackImg" src="assets/arbitrum.png" alt="Arbitrum" />
              <div>Arbitrum</div>
            </div>
            <div className="techStack">
              <img width="48px" className="techStackImg" src="assets/ethereum.png" alt="Ethereum" />
              <div>Ethereum</div>
            </div>
          <div className="techStack">
              <img width="48px" className="techStackImg" src="assets/antelope.png" alt="Antelope" />
              <div>EOS</div>
            </div>
            <div className="techStack">
              <img width="48px" className="techStackImg" src="assets/wax.webp" alt="WAX" />
              <div>WAX</div>
            </div>
            <div className="techStack">
              <img width="48px" className="techStackImg" src="assets/telos.png" alt="Telos" />
              <div>Telos</div>
            </div>
            <div className="techStack">
              <img width="48px" className="techStackImg" src="assets/ultra.webp" alt="Ultra" />
              <div>Ultra</div>
            </div>
            <div className="techStack">
              <img width="48px" className="techStackImg" src="assets/proton.png" alt="Ultra" />
              <div>Proton</div>
            </div>
          </div>
        </div>
        <div id="header5" className="header">
          <div className="title">Team</div>
          <div className="teamContainer">
            <div className="teamMember">
              <img className="profileImage" width="128px" height="128px" src="lennar.jpg" alt="React" />
              <div className="profileRight">
                <div className="profileNmae">Lennar</div>
                <div className="profileDescription">Full-stack frontend developer</div>
                <div className="profileDescriptionLong">BSc in Finance. 7 years leading a team in a silicon valley AI startup. A decade in web development.</div>
              </div>
            </div>
            <div className="teamMember">
              <img className="profileImage" width="128px" src="vlad.jpg" alt="React" />
              <div className="profileRight">
                <div className="profileNmae">Vlad</div>
                <div className="profileDescription">Smart contract developer</div>
                <div className="profileDescriptionLong">5 years experience in smart contract dev. Previously worked in PwC tech&legal department. Studied in Stockholm School of Economics.</div>

              </div>
            </div>
          </div>
        </div>
        <div id="header6" className="header">
          <div className="title">Let's chat!</div>
          <div className="contactsContainer">
            <a href="https://t.me/cxzvnk" target="_blank"><img className="contact" src="assets/telegram.webp" width="128px" alt="Telegram" /></a>
            <a href="mailto:asynchronousdevelopers@gmail.com"><img className="contact" src="assets/email.png" width="128px" alt="Email" /></a>
          </div>
        </div>
      </div>
      <button onClick={handleScrollDown} className="fixedScrollButton">Next</button>
      <ul className="background">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
    </>
  );
}

export default App;
